.cart {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  width: 85%;
  margin: 0;
  box-sizing: border-box;
}

.cart-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.add-custom-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.add-custom-item h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.add-custom-item form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-custom-item input,
.add-custom-item select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

.price-input-group {
  display: flex;
  gap: 10px;
}

.price-input-group input {
  flex: 2;
}

.price-input-group select {
  flex: 1;
}

.add-custom-item-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-custom-item-btn:hover {
  background-color: #0056b3;
}

.cart-empty {
  text-align: center;
  color: #666;
  font-style: italic;
}

.cart-items-container {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.cart-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.cart-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.item-details {
  flex: 1 1 100%;
  margin-bottom: 10px;
}

.item-name {
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
  display: block;
}

.item-price {
  color: #28a745;
  font-weight: bold;
  cursor: pointer;
}

.edit-price-hint {
  font-size: 12px;
  color: #6c757d;
  margin-left: 5px;
}

.price-edit {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.price-edit input {
  width: 80px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.price-edit button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.item-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.quantity-payment-wrapper {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.quantity-control {
  display: flex;
  align-items: center;
  gap: 5px;
}

.quantity-button {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  color: #333;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
}

.item-quantity {
  font-weight: bold;
  color: #333;
}

.toggle-payment-button {
  background-color: #17a2b8;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.remove-button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.cart-summary {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.subtotal,
.total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.total {
  font-weight: bold;
  font-size: 18px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.cart-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.checkout-button,
.clear-cart-button {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.checkout-button {
  background-color: #28a745;
  color: #fff;
}

.checkout-button:hover {
  background-color: #218838;
}

.clear-cart-button {
  background-color: #6c757d;
  color: #fff;
}

.clear-cart-button:hover {
  background-color: #5a6268;
}

@media (max-width: 640px) {
  .cart {
    width: 100%;
    padding: 15px;
  }

  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .item-actions {
    flex-direction: column;
    align-items: flex-start;
  }

  .quantity-payment-wrapper {
    width: 100%;
    justify-content: space-between;
  }

  .remove-button {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .cart-actions {
    flex-direction: column;
  }

  .checkout-button,
  .clear-cart-button {
    width: 100%;
    margin-bottom: 10px;
  }
}
