body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.app {
  display: flex;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.main-content {
  flex: 0 0 65%; /* 65% of the width */
  padding: 20px;
  background-color: #f9f9f9;
}

.cart-container {
  flex: 0 0 35%; /* 35% of the width */
  padding: 20px 40px 20px 30px;
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Add scroll if content overflows */
  max-height: 100vh; /* Limit height to viewport height */
  display: flex;
  justify-content: flex-start;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.search-and-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.search-container {
  flex: 1;
  display: flex;
  align-items: center;
}

.search-and-filter-container {
  display: flex;
  gap: 10px;
  width: 100%;
}

.search-input,
.number-filter-input {
  flex-grow: 1;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.search-input {
  flex-basis: 70%;
}

.number-filter-input {
  flex-basis: 30%;
}

.search-input:focus,
.number-filter-input:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.5);
}

.search-button {
  padding: 12px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #45a049;
}

.search-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  height: 38px; /* Match the height of the input */
}

.search-button:hover {
  background-color: #45a049;
}

.filter-select {
  flex: 1 1 calc(50% - 5px);
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  min-width: 120px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.pagination button {
  padding: 8px 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  font-size: 14px;
}

@media (max-width: 1200px) {
  .app {
    flex-direction: column;
  }

  .main-content,
  .cart-container {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
  }

  .filters {
    flex-direction: column;
  }

  .search-and-upload {
    flex-direction: column;
    align-items: stretch;
  }

  .search-container {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .filter-select {
    flex-basis: 100%;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .filters {
    flex-direction: row;
  }

  .filter-select {
    flex: 1 1 calc(33.33% - 10px);
  }
}

/* New styles for CORS error message */
.cors-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
}

.cors-error h2 {
  color: #d32f2f;
  margin-bottom: 20px;
}

.cors-error p {
  margin-bottom: 15px;
  max-width: 600px;
}

.cors-button {
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.cors-button:hover {
  background-color: #45a049;
}
