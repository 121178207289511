.table-responsive {
  overflow-x: auto;
  margin-bottom: 1rem;
  position: relative;
}

.warning-legend {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
}

.warning-legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.warning-legend-color {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  flex-shrink: 0;
}

.warning-legend-color.severe {
  background-color: #ffebee;
}

.warning-legend-color.moderate {
  background-color: #fff8e1;
}

.card-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.card-table th,
.card-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.card-table th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  font-size: 0.9em;
}

.card-table tbody tr:hover {
  background-color: #f9f9f9;
}

.card-table tbody tr:last-child td {
  border-bottom: none;
}

.card-table select {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.add-to-cart-btn {
  width: 100%;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart-btn.cash {
  background-color: #4CAF50;
}

.add-to-cart-btn.cash:hover {
  background-color: #45a049;
}

.add-to-cart-btn.credit {
  background-color: #2196F3;
}

.add-to-cart-btn.credit:hover {
  background-color: #0b7dda;
}

/* Hover image styles */
.table-responsive {
  position: relative;
}

.card-table tbody tr {
  position: relative;
}

.hover-image-container {
  position: absolute;
  top: 50%;
  right: -200px;
  transform: translateY(-50%);
  z-index: 10;
  pointer-events: none; /* Prevent the hover image from interfering with clicks */
}

.hover-image {
  width: 180px;
  height: auto;
  max-height: 250px;
  object-fit: contain;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.card-table td {
  position: relative; /* Make sure the td is positioned relatively for absolute positioning of the hover image */
}

/* Warning styles */
.warning-row-severe {
  background-color: #ffebee !important;
}

.warning-row-severe:hover {
  background-color: #ffcdd2 !important;
}

.warning-row-moderate {
  background-color: #fff8e1 !important;
}

.warning-row-moderate:hover {
  background-color: #ffe0b2 !important;
}

@media screen and (max-width: 600px) {
  .card-table {
    font-size: 0.8em;
  }

  .card-table th,
  .card-table td {
    padding: 8px 10px;
  }

  .add-to-cart-btn {
    padding: 6px 10px;
    font-size: 0.9em;
  }

  /* Hover image media query styles */
  .hover-image-container {
    display: none; /* Hide hover image on small screens */
  }

  .warning-legend {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
