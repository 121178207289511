.checkout-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.checkout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.back-button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.back-arrow {
  margin-right: 5px;
}

.checkout-content {
  display: flex;
  gap: 30px;
}

.checkout-form {
  flex: 1;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="email"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 10px;
}

.button-group {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.submit-button {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #45a049;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.leads-online-button {
  background-color: #2196F3;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.leads-online-button:hover {
  background-color: #1976D2;
}

.leads-online-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.order-summary {
  flex: 1;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 4px;
}

.add-custom-item {
  margin-bottom: 20px;
}

.custom-item-form {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.custom-item-form input,
.custom-item-form select {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-item-form button {
  padding: 8px 15px;
  background-color: #008CBA;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.order-items {
  margin-top: 20px;
}

.order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.item-details {
  flex: 2;
}

.item-name {
  font-weight: bold;
}

.item-info {
  font-size: 0.9em;
  color: #666;
}

.item-price,
.item-quantity {
  flex: 1;
  text-align: center;
}

.item-actions {
  flex: 2;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.quantity-btn,
.toggle-btn,
.remove-btn {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.quantity-btn {
  background-color: #f0f0f0;
}

.toggle-btn {
  background-color: #FFA500;
  color: white;
}

.remove-btn {
  background-color: #f44336;
  color: white;
}

.order-total {
  margin-top: 20px;
  text-align: right;
  font-size: 1.2em;
}

.error {
  color: #f44336;
  margin-bottom: 15px;
}
