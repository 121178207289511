.card-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
}

.card-popup {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  width: 95%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #718096;
  transition: all 0.2s ease;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 16px;
}

.close-button:hover {
  background-color: #e2e8f0;
  color: #2d3748;
}

.close-icon {
  line-height: 1;
}

.card-popup-content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.card-header {
  display: flex;
  gap: 24px;
}

.card-image-container {
  flex: 0 0 250px;
}

.card-image {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;
}

.tcgplayer-link {
  display: block;
  width: 100%;
  padding: 8px 16px;
  background-color: #f97316;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  text-decoration: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.tcgplayer-link:hover {
  background-color: #ea580c;
}

.tcgplayer-link svg {
  width: 16px;
  height: 16px;
}

.card-info {
  flex: 1;
  min-width: 0;
}

.card-name {
  font-size: 24px;
  color: #2d3748;
  margin: 0 0 16px;
  font-weight: 600;
}

.card-details {
  background-color: #f7fafc;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.card-details p {
  margin: 8px 0;
  color: #4a5568;
}

.card-details strong {
  color: #2d3748;
  margin-right: 8px;
}

.card-options {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
}

.card-options select {
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: white;
  color: #2d3748;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.card-options select:hover {
  border-color: #cbd5e0;
}

.card-options select:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

.price-inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.price-input {
  background-color: #f7fafc;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.price-input-controls {
  display: flex;
  align-items: center;
  flex: 1;
}

.price-input label {
  font-weight: 500;
  color: #2d3748;
  width: 100px;
}

.price-input input[type="number"] {
  width: 100px;
  padding: 8px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 14px;
}

.price-input input[type="range"] {
  flex: 1;
  margin: 0 12px;
}

.price-input span {
  color: #718096;
  font-size: 14px;
}

.sku-list {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.sku-list h4 {
  margin: 0;
  padding: 16px;
  background-color: #f7fafc;
  color: #2d3748;
  font-size: 16px;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-container {
  overflow-x: auto;
  max-width: 100%;
  position: relative;
}

.sku-list table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px;
}

.sku-list th,
.sku-list td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
  font-size: 14px;
  white-space: nowrap;
}

.sku-list th {
  background-color: #f7fafc;
  font-weight: 600;
  color: #4a5568;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.05em;
  position: sticky;
  top: 0;
  z-index: 1;
}

.sku-list tr:last-child td {
  border-bottom: none;
}

.sku-list tbody tr {
  transition: background-color 0.2s ease;
}

.sku-list tbody tr:hover {
  background-color: #f7fafc;
}

.price-cell {
  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-block;
  min-width: 80px;
  text-align: right;
}

.price-cell.market {
  background-color: #ebf8ff;
  color: #2b6cb0;
}

.price-cell.low {
  background-color: #f0fff4;
  color: #2f855a;
}

.price-cell.mid {
  background-color: #fffaf0;
  color: #744210;
}

.price-cell.high {
  background-color: #fff5f5;
  color: #c53030;
}

.price-cell.list {
  background-color: #faf5ff;
  color: #6b46c1;
}

.price-cell.direct {
  background-color: #f0f5ff;
  color: #3182ce;
}

.col-group {
  border-right: 1px solid #e2e8f0;
  padding-right: 8px;
  margin-right: 8px;
}

.col-group:last-child {
  border-right: none;
  padding-right: 0;
  margin-right: 0;
}

.price-type-header {
  font-size: 11px;
  color: #718096;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 4px;
}

.loading-indicator {
  font-size: 14px;
  color: #718096;
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
}

.loading-indicator::after {
  content: '';
  width: 12px;
  height: 12px;
  margin-left: 8px;
  border: 2px solid #e2e8f0;
  border-top-color: #4299e1;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.sku-list th:nth-child(-n+3),
.sku-list td:nth-child(-n+3) {
  position: sticky;
  left: 0;
  background-color: inherit;
  z-index: 2;
}

.sku-list th:nth-child(-n+3) {
  background-color: #f7fafc;
}

.sku-list tr:hover td:nth-child(-n+3) {
  background-color: #f7fafc;
}

.price-columns {
  display: flex;
  gap: 24px;
  padding: 16px;
  background-color: #f7fafc;
  border-bottom: 1px solid #e2e8f0;
}

.price-column {
  flex: 1;
}

.price-column-header {
  font-size: 12px;
  font-weight: 600;
  color: #4a5568;
  margin-bottom: 4px;
}

.price-column-value {
  font-size: 16px;
  font-weight: 500;
  color: #2d3748;
}

.card-actions {
  display: flex;
  gap: 12px;
}

.add-to-cart-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  min-width: 120px;
}

.add-to-cart-btn.cash {
  background-color: #48bb78;
  color: white;
}

.add-to-cart-btn.cash:hover {
  background-color: #38a169;
}

.add-to-cart-btn.credit {
  background-color: #4299e1;
  color: white;
}

.add-to-cart-btn.credit:hover {
  background-color: #3182ce;
}

@media (max-width: 1024px) {
  .card-header {
    flex-direction: column;
  }

  .card-image-container {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
  }

  .price-input {
    flex-direction: column;
    align-items: stretch;
  }

  .price-input-controls {
    flex-direction: column;
    gap: 12px;
  }

  .price-input input[type="range"] {
    width: 100%;
    margin: 8px 0;
  }

  .price-input span {
    text-align: center;
    width: 100%;
  }
}
